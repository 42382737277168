@use "../../global"as *;

.u-pt50 {
  padding-top: 50px;
}

.u-pt85 {
  padding-top: 85px;

  @include mq-down(sm) {
    padding-top: 30px;
  }
}

.u-pt120 {
  padding-top: 120px;

  @include mq-down(sm) {
    padding-top: 30px;
  }
}

.u-pt-half {
  padding-top: 0.6em;
}