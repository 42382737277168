@use "../global"as *;

/* _header.scss */
.l-header {
  height: 145px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 52px;
  background-color: $color-white;

  @include mq-down(header) {
    height: 132px;
    flex-wrap: wrap;
    padding: 0;
  }

  .menu-mode & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 998;
    // margin-top: 0 !important;
    // transform: none !important;
  }

  .is-scrolled & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 998;
    margin-top: -150px;
    transition: transform .6s;
  }

  .is-scrolled.header-mode & {
    transform: translateY(150px);
  }

  &__logo {
    width: 428px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mq-down(header) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      width: 100%;
      margin: auto;
      padding: 11px 0 13px;
      border-bottom: 1px solid $color-purple;

      a {
        width: 240px;
        display: block;
        margin: auto;
      }
    }
  }

  &__left {
    display: flex;
    gap: 75px;

    @include mq-down(xl) {
      gap: 20px;
    }

    @include mq-down(header) {
      width: 50%;
      align-items: center;
      height: 50px;
      padding-left: 27px;
    }
  }

  &__left-txts {
    padding-top: 20px;

    @include mq-down(header) {
      display: none;
    }
  }

  &__left-txt-auth {
    font-size: 1.1rem;
    line-height: 1;
    padding: 5px 6px;
    border: 1px solid $color-brown;
  }

  &__left-txt-msg {
    margin-top: 1em;
  }

  &__right {

    @include mq-down(header) {
      width: 50%;
      align-items: center;
      height: 50px;
      padding-right: 20px;
    }
  }
}