@use "../../global"as *;

header {
  margin-top: -100vh;
}

.p-corners {
  position: sticky;
  z-index: 9999;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  %corners {
    content: "";
    display: block;
    width: clamp(60px, 15vw, 120px);
    height: clamp(60px, 15vw, 120px);
    position: absolute;
    background-image: url(../img/common/bg-corner.svg);
    background-size: clamp(60px, 15vw, 120px) clamp(60px, 15vw, 120px);
  }

  &__top {
    width: 100%;

    &::before {
      @extend %corners;
      top: 0;
      left: 0;
    }

    &::after {
      @extend %corners;
      top: 0;
      right: 0;
      transform: rotate(90deg);
    }
  }

  &__bottom {
    position: absolute;
    z-index: 9999;
    pointer-events: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: clamp(60px, 15vw, 120px);

    &::before {
      @extend %corners;
      bottom: 0;
      left: 0;
      transform: rotate(270deg);
    }

    &::after {
      @extend %corners;
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }
  }
}