@use "../../global"as *;

.c-btn-readmore {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    height: 47px;
    width: 160px;
    margin: auto;
    color: $color-brown;
    font-family: $ff-kurale;
    padding-left: 10px;

    &::after {
      content: "";
      display: block;
      width: 18px;
      height: 15px;
      background-image: url(../img/common/icon-arrow.svg);
      background-size: 18.04px auto;
      background-position: center left;
      background-repeat: no-repeat;
      flex-shrink: 0;
      margin-left: 9px;
    }
  }

  &--purple {
    a {
      border-image: url(../img/common/border-003.png) 30 fill / 15px round;
    }
  }

  &--white {
    a {
      border-image: url(../img/common/border-002.png) 30 fill / 15px round;
    }
  }

  &--brown {
    text-align: center;

    a {
      display: inline-flex;
      padding: 0 20px 0 30px;
      width: auto;
      color: $color-white;
      border-image: url(../img/common/border-004.png) 30 fill / 15px round;

      &::after {
        background-image: url(../img/common/icon-arrow-gray.svg);
      }
    }
  }

  &--green {
    text-align: center;

    a {
      display: inline-flex;
      padding: 0 20px 0 30px;
      width: auto;
      color: $color-white;
      border-image: url(../img/common/border-005.png) 30 fill / 15px round;

      &::after {
        background-image: url(../img/common/icon-arrow-gray.svg);
      }
    }
  }
}