@use "../../global"as *;

.p-top-mv {
  width: 100%;
  height: calc(100vh - 145px);
  background-color: $color-temp01;
  position: relative;

  &__logo {
    width: clamp(200px, 70%, 330px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .3)) drop-shadow(0px 0px 10px rgba(0, 0, 0, .3));
  }

  &__slider {
    width: 100%;
    height: calc(100vh - 145px);

    .swiper-slide {
      width: 100%;
      height: calc(100vh - 145px);

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        &.test {
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.c-mv {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .p-hiroshima & {
    background-image: url(../img/hiroshima/mv.png);

    @include mq-retina() {
      background-image: url(../img/hiroshima/mv@2x.png);
    }
  }

  .p-green & {
    background-image: url(../img/green/mv.png);

    @include mq-retina() {
      background-image: url(../img/green/mv@2x.png);
    }
  }

  @include mq-down(sm) {
    padding-bottom: 80%;
  }

}

.c-mvTtl {
  position: absolute;
  top: 1em;
  left: 0;
  padding-left: 52px;
  padding-bottom: 0.3em;
  line-height: 1;
  color: $color-white;
  border-bottom: 1px solid $color-white;
  font-size: clamp(2rem, 2.5vw, 4rem);
  font-weight: 400;
  letter-spacing: 0.05em;

  @include mq-down(sm) {
    padding-left: 27px;
  }
}