@use "../../global"as *;

.c-ham {
  >a {
    display: block;
    position: relative;
    z-index: 999;
    width: 24px;
    height: 20px;
    margin-top: 80px;

    @include mq-down(header) {
      margin-top: 0;
    }

    span {
      position: absolute;
      width: 100%;
      left: 0;
      height: 2px;
      border-radius: 0.5px;
      background-color: $color-gray;

      &:nth-of-type(1) {
        top: 0;
        transition: all 0.5s;
      }

      &:nth-of-type(2) {
        top: 9px;
        opacity: 1;
        transition: all 0.5s;
      }

      &:nth-of-type(3) {
        top: 18px;
        transition: all 0.5s;
      }
    }


    @include mq-down(sm) {
      &.is-active {
        span {
          &:nth-of-type(1) {
            top: 10px;
            transform: rotate(45deg);
            background-color: $color-black !important;
            height: 2px;
            border-radius: 1px;
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            top: 10px;
            transform: rotate(-45deg);
            background-color: $color-black !important;
            height: 2px;
            border-radius: 1px;
          }
        }
      }
    }
  }
}