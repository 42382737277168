@use "../global"as *;

@import url('https://fonts.googleapis.com/css2?family=Kurale&family=Niconne&family=Noto+Sans+JP:wght@300;500&display=swap');

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.4rem;
  //letter-spacing: 0.05em;
  line-height: 1.5;
  color: $color-brown;
  font-weight: 300;
  background-color: $color-white;
}

a {
  color: $color-brown;

  &:hover {
    opacity: .6;
  }
}

button {
  &:hover {
    opacity: .6;


    @include mq-down(sm) {
      opacity: 1;
    }
  }
}

img {
  width: 100%;
  height: auto;
}

// * {
//   outline: 1px solid red !important;
// }