/* _footer.scss */
@use "../global"as *;

.l-footer {
  background-color: $color-brown;
  color: $color-white;
  padding: 50px 0 22px;

  @include mq-down(sm) {
    padding: 23px 0 30px;
  }

  a {
    color: $color-white;
  }
}

.l-footer-nav {
  position: relative;

  @include mq-down(sm) {
    display: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 640px;
    gap: 43px 0;
    align-content: space-between;
  }

  &__item {

    &--en {
      font-size: 1.6rem;
      font-family: $ff-kurale;
    }

    >a {
      font-weight: 500;
    }
  }

  &__sub-list {
    font-size: 1.2rem;
    line-height: 2;
    margin-top: 17px;

    a {
      color: $color-purple;
    }
  }



  &__sns-item {
    a {
      display: flex;
      align-items: center;
      font-family: $ff-kurale;
      font-size: 1.6rem;
      gap: 7px;
    }

    img,
    svg {
      width: 24px;
    }

    &.color-green {
      svg {

        path,
        circle {
          fill: $color-green;
        }
      }
    }
  }

  &__sns-list {
    position: absolute;
    bottom: 10px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-right: 10px;

    &--contacts {
      margin-top: 23px;
      gap: 31px;
      padding-right: 0;

      a {
        gap: 10px;
      }

      img {
        width: 11px;
      }
    }
  }
}

.l-footer-bottom {
  margin-top: 70px;

  @include mq-down(sm) {
    margin-top: 0;
  }

  &__pb-sub {
    display: flex;
    background-image: url(../img/common/border-end-left.png), url(../img/common/border-end-right.png);
    background-size: 27px, 27px;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    height: 27px;
    position: relative;
    justify-content: center;

    @include mq-down(sm) {
      background-image: none;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-purple;
      position: absolute;
      top: 13px;
      left: 0;

      @include mq-down(sm) {
        display: none;
      }
    }
  }

  &__pb-sub-inner {
    position: relative;
    display: block;
    background-color: $color-brown;
    height: 27px;
    line-height: 25px;
    padding: 0 3em;
    color: $color-purple;
    font-size: 1rem;
  }

  &__pb-main {
    display: block;
    box-sizing: content-box;
    max-width: 260px;
    padding: 30px;
    margin: 8px auto 0;

    @include mq-down(sm) {
      padding: 0 30px;
    }
  }

  &__copy {
    display: block;
    text-align: center;
    margin-top: 22px;
    font-size: 1rem;
    color: $color-purple;
  }
}