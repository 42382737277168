@use "../../global"as *;

.c-end-border {
  border-bottom: 1px solid $color-brown;
  padding-bottom: 60px;

  @include mq-down(sm) {
    padding-bottom: 30px;
  }

  .p-green & {
    border-color: $color-green;
  }
}