$color-white: #ffffff;
$color-black: #333;
$color-gray: #707070;
$color-gray2: #EFEEEC;
$color-brown: #4E2F28;
$color-green: #205A3F;
$color-pink: #FC69A3;
$color-purple: #DCD3EC;
$color-yellow: #FADD00;
$color-temp01: rgba(255, 0, 0, 0.2);
$color-temp02: rgba(0, 255, 0, 0.2);
$color-temp03: rgba(0, 0, 255, 0.2);

$ff-noto: "Noto Sans JP",
sans-serif;
$ff-kurale: "Kurale",
serif;
$ff-niconne: "Niconne",
serif;
$ff-mix: "Kurale",
"Noto Sans JP",
sans-serif;

$max-width: 1080px;
$content-margin: 50px;
$content-margin-sp: 20px;
$item-margin: 15px;
$row: 6;
$item-size: calc($max-width / $row - $item-margin * 2);

$item-margin-md: 7;
$row-md: 4;
$item-size-md: setFlexItemSize($row-md, $item-margin-md);

$item-margin-c2: 5;
$row-c2: 2;
$item-size-c2: setFlexItemSize($row-c2, $item-margin-c2);

$item-margin-c3: 2.49;
$row-c3: 3;
$item-size-c3: setFlexItemSize($row-c3, $item-margin-c3);