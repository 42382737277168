@use "../../global"as *;

.c-header-links {
  margin-top: 38px;

  @include mq-down(header) {
    margin-top: 0;
  }

  &__item {
    a {
      display: flex;
      align-items: center;
      font-family: $ff-kurale;
      font-size: 1.6rem;
      gap: 7px;
    }

    img,
    svg {
      width: 24px;
    }

    &.color-green {
      svg {

        path,
        circle {
          fill: $color-green;
        }
      }
    }
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-right: 10px;

    @include mq-down(header) {
      display: none;
    }

    &--contacts {
      margin-top: 23px;
      gap: 31px;
      padding-right: 0;

      @include mq-down(header) {
        display: flex;
        align-items: center;
        height: 50px;
      }

      @include mq-down(header) {
        margin-top: 0;
      }

      a {
        gap: 10px;
      }

      img {
        width: 11px;
      }
    }
  }
}