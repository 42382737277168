@use "../../global"as *;


.p-places-main {
  padding-top: 80px;

  @include mq-down(sm) {
    padding-top: 40px;
  }

  &__logo {
    max-width: 640px;
    padding: 0 20px;
    margin: auto;
  }

  &__ttl {
    font-size: 2.8rem;
    margin-top: 47px;
    text-align: center;
    font-weight: 500;

    @include mq-down(sm) {
      font-size: 2rem;
      margin-top: 25px;
    }
  }

  &__dsc {
    font-size: 1.8rem;
    line-height: 2;
    text-align: center;
    margin-top: 35px;

    @include mq-down(sm) {
      font-size: 1.6rem;
      margin-top: 25px;
    }
  }

  &__views {
    padding: 0 50px;
    display: flex;
    max-width: 1280px;
    margin: 25px auto 0;

    @include mq-down(sm) {
      padding: 0;
    }
  }

  &__tags {
    max-width: 450px;
    margin: 30px auto 0;

    @include mq-down(sm) {
      padding: 0 20px;
      font-size: 1.2rem;
      margin-top: 20px;
    }

    &.color-green .p-top-access__tag {
      border-color: $color-green;
    }
  }
}

.p-places-staff {
  padding-top: 100px;

  @include mq-down(sm) {
    padding-top: 30px;
  }

  &__list {
    max-width: 1280px;
    margin: 30px auto 0;
    padding: 0 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px 0;

    @include mq-down(sm) {
      margin-top: 15px;
      padding: 0 20px;
      gap: 20px 0;
      max-width: 450px;
    }
  }

  &__item {
    width: 20%;

    @include mq-down(sm) {
      width: 50%;
    }
  }

  &__name {
    padding-top: 0.4em;

    span {
      display: block;
      text-align: center;
    }
  }

  &__more {
    vertical-align: top;
    text-align: center;
    margin-top: .7em;

    &::after {
      content: "";
      display: inline-block;
      width: 18px;
      height: 13px;
      background-image: url(../img/common/icon-arrow.svg);
      background-size: 18px auto;
      background-position: center right;
      background-repeat: no-repeat;
      flex-shrink: 0;
      position: relative;
      top: 0.1em;
      margin-left: 0.8em;
    }
  }
}

.p-places-access {
  padding-top: 100px;

  @include mq-down(sm) {
    padding-top: 30px;
  }

  &__flex {
    display: flex;
    justify-content: space-between;

    @include mq-down(sm) {
      flex-direction: column-reverse;
    }
  }

  &__map {
    width: 46.73%;

    @include mq-down(sm) {
      width: 100%;
      position: relative;
      height: 0;
      padding-bottom: 100%;
      margin-top: 2em;
    }

    iframe {
      width: 100%;

      @include mq-down(sm) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  &__main {
    width: 47.86%;

    @include mq-down(sm) {
      width: 100%;
    }
  }

  &__ttl {
    font-size: 1.6rem;
    font-weight: 400;
  }

  &__venue {
    margin-top: 1em;
  }

  &__contacts {
    margin-top: 1em;
  }

  &__tel {
    font-family: $ff-kurale;
    font-size: 1.6rem;
  }

  &__fax {
    span {
      display: inline-block;
      padding-right: 0.5em;
    }
  }

  &__parking {
    margin-top: 1em;

    span {
      display: inline-block;
      padding-left: 1em;
    }
  }
}

.p-places-access-driving {
  margin-top: 4.5em;

  @include mq-down(sm) {
    margin-top: 2em;
  }

  &__ttl {
    font-weight: 500;
    font-size: 1.6rem;
  }

  &__dt {
    font-weight: 500;
  }

  &__dd+&__dt {
    margin-top: 1.5em;
  }

  &__dl+&__ttl {
    margin-top: 2em;
  }

  &__ttl+&__dl {
    margin-top: 0.2em;
  }
}

.p-places-other {
  padding: 100px 0;

  &__ttl {
    max-width: 640px;
    padding: 0 20px;
    margin: auto;
  }

  &__dsc {
    font-size: 1.8rem;
    line-height: 2;
    text-align: center;
    margin-top: 35px;

    @include mq-down(sm) {
      font-size: 1.6rem;
      margin-top: 25px;
    }
  }

  .c-btn-readmore {
    margin-top: 2em;
  }
}