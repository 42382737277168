@use "../../global"as *;

.swiper-pagination-bullet {
  background: $color-white;
  opacity: .8;
}

.swiper-pagination-bullet-active {
  background: $color-brown;
  opacity: .8;
}