@use "../../global"as *;

.c-nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  background-color: $color-white;
  z-index: 9999;
  overflow: hidden;

  @include mq-down(sm) {
    top: 132px;
    height: calc(100vh - 133px);
    border-top: 1px solid $color-brown;
  }

  .menu-mode & {
    opacity: 1;
    visibility: visible;
  }

  &__close {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 49px;
    height: 49px;
    z-index: 10000;

    @include mq-down(sm) {
      display: none;
    }

    a {
      display: block;
      width: 49px;
      height: 49px;
    }

    span {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-brown;
      position: absolute;
      top: 24px;
      left: 0;

      &:first-of-type {
        transform: rotate(45deg);
      }

      &:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }

  &__main {
    width: 100%;
    position: relative;
    z-index: 9999;
    height: 100vh;
    overflow-y: scroll;
    box-sizing: content-box;
    padding-right: 20px;

    @include mq-down(sm) {
      padding-right: 0;
    }

    .l-inner {
      padding-top: 50px;
      padding-bottom: 50px;

      @include mq-down(sm) {
        padding: 0;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 710px;
    gap: 43px 0;
    align-content: space-between;

    @include mq-down(sm) {
      display: block;
      height: auto;
      padding-bottom: 200px;
    }
  }

  &__item {

    @include mq-down(sm) {
      padding: 0;
      border-bottom: 1px solid $color-brown;

      a {
        padding-left: 20px;
        padding-right: 20px;

        br {
          display: none;
        }
      }

      &.has-submenu {
        position: relative;

        &::before,
        &::after {
          content: "";
          display: block;
          width: 16px;
          height: 2px;
          background-color: $color-brown;
          position: absolute;
          top: 25px;
          right: 20px;
        }

        &::after {
          transform: rotate(90deg);
          transition: transform .3s, opacity .3s;
        }

        &.is-open::after {
          transform: rotate(0deg);
          opacity: 0;
        }
      }

      &:not(.has-submenu) {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 18px;
          height: 15px;
          background-image: url(../img/common/icon-arrow.svg);
          background-size: 18.04px auto;
          background-position: center left;
          background-repeat: no-repeat;
          flex-shrink: 0;
          margin-left: 9px;
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
    }

    &--en {
      font-size: 1.6rem;
      font-family: $ff-kurale;
    }

    &--extend {
      min-height: 250px;

      @include mq-down(sm) {
        min-height: unset;
      }
    }

    >a {
      font-weight: 500;

      @include mq-down(sm) {
        font-weight: 300;
        display: block;
        padding: 15px 20px;
      }
    }
  }

  &__sub-list {
    font-size: 1.2rem;
    line-height: 2;
    margin-top: 17px;

    @include mq-down(sm) {
      height: auto;
      max-height: 0;
      transition: all .5s;
      overflow: hidden;
      margin-top: 0;

      .is-open & {
        transition: all .9s;
        max-height: 500px;
      }
    }

    &--thumbs {
      max-width: 182px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 2px;

      .c-nav__sub-item {
        width: 45%;
        margin-top: 15px;
      }

      span {
        display: block;
        line-height: 1.2;
        margin-top: .4em;
      }
    }
  }

  &__sub-item {
    @include mq-down(sm) {
      &+& {
        margin-top: 1em;
      }

      &:last-of-type {
        padding-bottom: 15px;
      }
    }
  }

}