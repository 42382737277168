@use "../../global"as *;

.c-foot-info {
  padding: 100px 0 147px;
  background-image: url(../img/common/bg-foot.png);
  background-position: bottom center;
  background-size: 1510px auto;
  background-repeat: repeat-x;
  position: relative;

  @include mq-down(sm) {
    padding: 51px 0 80px;
    background-size: 847px auto;
  }

  &__dl {
    font-family: $ff-mix;
  }

  &__dl-row {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
  }

  &__dt {
    padding-right: 0.5em;

    &--colon {
      &::after {
        content: ":";
      }
    }
  }

  &__dd {
    min-width: 6.7em;
  }

  &__area {
    width: 258px;
    height: 214px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 115px);
    z-index: -1;

    @include mq-down(sm) {
      width: 230px;
      height: 148px;
    }
  }

  &__moon {
    width: 114px;
    height: 114px;
    background-color: $color-yellow;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform-origin: 258px 214px;
    transform: rotate(-20deg);
    opacity: .25;

    @include mq-down(sm) {
      transform-origin: 230px 148px;
    }
  }
}