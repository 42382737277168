@use "../../global"as *;


.p-top-notice {
  padding: 80px 0;

  @include mq-down(sm) {
    padding: 50px 0;
  }

  &__container {
    display: flex;
    border-image-source: url(../img/common/border-001.png);
    border-image-slice: 33.33%;
    border-image-repeat: repeat;
    border-image-width: 23px;
    border-style: solid;
    padding: 20px;

    @include mq-down(sm) {
      display: block;
      border-image-width: 22px;
      padding: 20px 8% 15px;
    }
  }

  &__ttl {
    width: 10em;
    flex-shrink: 0;
    color: $color-pink;
    display: flex;
    align-items: center;
    border-right: 1px solid $color-gray;

    @include mq-down(sm) {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $color-gray;
      padding: .4em 0 1em;
      text-align: center;
      display: block;
    }
  }

  &__list {
    padding: 10px 33px;

    @include mq-down(sm) {
      padding: 15px 0;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 25px;
      height: 15px;
      background-image: url(../img/common/icon-arrow.svg);
      background-size: 18.04px auto;
      background-position: center left;
      background-repeat: no-repeat;
      flex-shrink: 0;
    }

    @include mq-down(sm) {
      a {
        text-decoration: underline;
      }
    }

    &+& {
      margin-top: 1.5em;

      @include mq-down(sm) {
        margin-top: 0.8em;
      }
    }
  }
}

.p-top-slider {
  .swiper-wrapper {
    transition-timing-function: ease-in-out;
  }
}

.p-top-tabs {
  margin-top: 75px;

  @include mq-down(sm) {
    margin-top: 50px;
  }

  &__tab-list-wrapper {
    @include mq-down(sm2) {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 36px;
    }
  }

  &__tab-list {
    display: flex;
    padding: 0 4.06%;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;

    @include mq-down(sm2) {
      height: 36px;
      width: 600px;
      padding: 0 20px;
      position: absolute;
      top: 0;
      left: 0;
      justify-content: flex-start;
      gap: 3px;
      transition: none;

      .is-animated & {
        transition: left .3s;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-purple;
      width: clamp(110px, 19%, 208px);
      text-align: center;
      height: 48px;
      overflow: hidden;
      font-family: $ff-kurale;
      font-size: 1.6rem;
      position: relative;

      &[aria-selected=true] {
        background-color: $color-brown;
        color: $color-white;
      }

      @include mq-down(sm) {
        height: 36px;
        width: auto;
        padding: 0 20px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        background-color: $color-white;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        position: absolute;
        top: -13px;

        @include mq-down(sm) {
          display: none;
        }

      }

      &::before {
        left: -11px;
      }

      &::after {
        right: -11px;
      }
    }
  }

  &__panel {
    padding: 44px 4.06% 55px;
    background-color: $color-brown;

    @include mq-down(sm) {
      padding: 40px 20px 55px;
    }
  }
}

.p-top-ourworks {
  color: $color-white;

  .c-btn-readmore {
    margin-top: 60px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-1 * (100% - 17.687% * 5) / (5 * 2));
    gap: 36px 0;

    @include mq-down(lg) {
      margin: 0 calc(-1 * (100% - 22% * 4) / (4 * 2));
    }

    @include mq-down(sm) {
      margin: 0 calc(-1 * (100% - 45% * 2) / (2 * 2));
    }
  }

  &__item {
    width: 17.687%;
    margin: 0 calc((100% - 17.687% * 5) / (5 * 2));

    a {
      color: $color-white;
      display: block;
    }

    @include mq-down(lg) {
      width: 22%;
      margin: 0 calc((100% - 22% * 4) / (4 * 2));

      &:nth-of-type(n+9) {
        display: none;
      }
    }

    @include mq-down(sm) {
      width: 45%;
      margin: 0 calc((100% - 45% * 2) / (2 * 2));
    }
  }

  &__thumb {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__txt {
    margin-top: 0.7em;
  }

  &__by-cat {
    margin-top: 60px;

  }

  &__by-cat-container {
    display: flex;

    @include mq-down(sm) {
      display: block;
    }
  }

  &__subttl {
    font-size: 1.8rem;
    width: 16.5em;
    flex-shrink: 0;

    @include mq-down(sm) {
      width: 100%;
      text-align: center;
    }
  }

  &__cat-list {
    display: flex;
    flex-wrap: wrap;
    gap: 25px 0;
    max-width: 730px;

    @include mq-down(sm) {
      margin-top: 30px;
    }

    a {
      padding: 0 .5em;
      width: calc(100% + 1px);
      border-left: 1px solid $color-white;
      border-right: 1px solid $color-white;
      margin-left: -1px;
      color: $color-white;
    }
  }
}

.p-top-aboutus {
  &__list {
    position: relative;
    width: 100%;
  }

  &__item {
    background-color: $color-purple;
    width: 44.728%;
    padding: 3.061% 4.422% 3.793%;

    @include mq-down(sm) {
      width: 100%;
      padding: 50px 20px 40px;
    }

    &+& {
      margin-top: 40px;
    }

    &--why {
      width: 52.721%;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0 !important;

      @include mq-down(sm) {
        margin-top: 40px !important;
        width: 100%;
        position: static;
      }

      .c-text--md {
        margin-top: 1.5em;
      }
    }
  }

  &__ttl {
    font-size: 2.4rem;
    text-align: center;
    font-weight: 500;
    padding-bottom: 1em;

    @include mq-down(sm) {
      font-size: 1.6rem;
    }

    p+& {
      margin-top: 2.5em;
    }

    &--fancy {
      padding-bottom: 0;
    }

    &--en {
      display: block;
      font-family: $ff-niconne;
      font-size: 4.8rem;
    }

    &--ja {
      display: block;
      font-size: clamp(1.4rem, 1.407vw, 1.8rem);
      line-height: 2;
      padding-bottom: 2em;
    }
  }

  &__img {
    &--symphonic {
      margin-top: 14px;
    }

    &--why {
      margin-top: 40px;
      margin-bottom: 23px;
    }
  }

  .c-btn-readmore {
    margin-top: 40px;
  }

  &__txt {
    &--center {
      display: block;
      text-align: center;
      font-weight: 500;
      padding: 1em 0;
    }
  }

  &__why-list {
    display: flex;
    margin: 35px -9% 0 -7.7%;
    gap: 0 1.6%;

    @include mq-down(sm) {
      display: block;
    }
  }

  &__why-item {

    @include mq-down(sm) {
      max-width: 200px;
      margin: auto;
    }
  }

  &__movie-list {
    margin-top: 30px;
  }

  &__movie-item {
    &+& {
      margin-top: 0.5em;
    }

    &--lg {
      margin-bottom: 25px;

      figcaption {
        // display: flex;
        // align-items: center;
        margin-top: 10px;
        padding-left: 1em;
        text-indent: -1em;
        vertical-align: top;

        &::after {
          content: "";
          display: inline-block;
          width: 30px;
          height: 15px;
          background-image: url(../img/common/icon-arrow.svg);
          background-size: 18.04px auto;
          background-position: center right;
          background-repeat: no-repeat;
          position: relative;
          top: 0.15em;
        }
      }
    }
  }

  &__movie-ttl {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 30.16px;
      height: 17px;
      background-image: url(../img/common/icon-play.svg);
      background-size: 24.07px auto;
      background-position: center left;
      background-repeat: no-repeat;
      flex-shrink: 0;
    }

    &--lg {
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      padding-bottom: 1em;
      display: block;
      width: 100%;

      &::before {
        display: none;
      }
    }
  }
}

.p-top-news {

  .c-btn-readmore {
    margin-top: 60px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-1 * (100% - 17.687% * 5) / (5 * 2));
    gap: 36px 0;

    @include mq-down(lg) {
      margin: 0 calc(-1 * (100% - 22% * 4) / (4 * 2));
    }

    @include mq-down(sm) {
      margin: 0 calc(-1 * (100% - 43% * 2) / (2 * 2));
    }
  }

  &__item {
    width: 17.687%;
    margin: 0 1.157%;

    &.cat-news {
      a::before {
        background-image: url(../img/common/icon-topics-news.svg);
      }
    }

    &.cat-present {
      a::before {
        background-image: url(../img/common/icon-topics-present.svg);
      }
    }

    &.cat-event {
      a::before {
        background-image: url(../img/common/icon-topics-event.svg);
      }
    }

    &.cat-sweets {
      a::before {
        background-image: url(../img/common/icon-topics-special.svg);
      }
    }

    a {
      color: $color-white;
      display: block;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 38px;
        height: 38px;
        position: absolute;
        top: -19px;
        left: -19px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
      }
    }

    time {
      font-family: $ff-kurale;
      font-size: clamp(1.4rem, 1.407vw, 1.8rem);
      text-align: center;
      display: block;
      margin: .5em 0;
    }

    @include mq-down(lg) {
      width: 22%;
      margin: 0 calc((100% - 22% * 4) / (4 * 2));

      &:nth-of-type(n+9) {
        display: none;
      }
    }

    @include mq-down(sm) {
      width: 43%;
      margin: 0 calc((100% - 43% * 2) / (2 * 2));
    }
  }

  &__thumb {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    position: relative;
    border-radius: clamp(10px, 2.345vw, 30px);
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__txt {
    max-height: 3em;
    overflow: hidden;
  }
}

.p-top-price {
  &__list {
    display: flex;
    justify-content: center;
    gap: 5%;

    @include mq-down(sm) {
      display: block;
    }
  }

  &__item {
    width: 33.33%;
    max-width: 260px;
    color: $color-white;

    @include mq-down(sm) {
      width: 100%;
      margin: 20px auto 0;
    }
  }

  &__q {
    width: 96.92%;
    margin: auto;
  }

  &__a {
    margin-top: 25px;
    font-size: 1.6rem;

    &::before {
      content: "A";
      display: block;
      color: $color-yellow;
      font-family: $ff-kurale;
      float: left;
      font-size: 4.8rem;
      padding-right: 0.3em;
      line-height: 1;
    }

    &::after {
      clear: both;
    }
  }

  .c-btn-readmore {
    margin-top: 60px;
  }

}

.p-top-movie {
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 60px 0;
    max-width: 980px;
    margin: auto;
  }

  &__item {
    width: 46.734%;
  }

  &__embed {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__ttl {
    font-size: 1.6rem;
    margin-top: 1.2em;
    color: $color-white;
    height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq-down(sm) {
      font-size: 1.2rem;
    }
  }

  &__link {
    text-align: center;
    margin-top: 60px;

    a {
      color: $color-white;
      display: inline-block;
      font-size: 1.6rem;

      @include mq-down(sm) {
        font-size: 1.4rem;
      }
    }

    i {
      display: inline-block;
      max-width: 55px;
      margin-top: 15px;
    }
  }
}

.p-top-access {
  margin-top: 80px;

  @include mq-down(sm) {
    margin-top: 50px;
  }

  &__txt {
    font-size: 1.8rem;
    text-align: center;
  }

  &__split {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @include mq-down(sm) {
      display: block;
    }
  }

  &__item {
    width: 46.735%;

    @include mq-down(sm) {
      width: 100%;

      &+& {
        margin-top: 40px;
      }
    }
  }

  &__ttl {
    aspect-ratio: 3.0734;
  }

  &__dsc {
    margin-top: 30px;
  }

  &__movie {
    margin-top: 5px;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__details {
    background-color: $color-gray2;
  }

  &__tags {
    padding: 22px 13px 0;
    gap: .7em 1em;
    margin-top: -0.7em;

    @include mq-down(sm) {
      font-size: 1rem;
    }
  }

  &__tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-brown;
    border-radius: 5em;
    padding: .25em 1.3em;
    margin-top: 0.7em;

    &:not(:last-of-type) {
      margin-right: .5em;
    }
  }

  .c-btn-readmore {
    margin-top: 30px;
    padding: 0 13px;

    a {
      font-family: $ff-noto;
    }
  }

  &__dl {
    margin-top: 17px;
    font-family: $ff-mix;

    &--green {
      color: $color-green;
    }
  }

  &__dl-row {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;

    &--lg {
      font-size: 2.4rem;
    }
  }

  &__dt {
    padding-right: 0.5em;

    &--colon {
      &::after {
        content: ":";
      }
    }
  }

  &__dd {
    min-width: 6.7em;
  }

  &__map {
    margin-top: 10px;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}