@use "../global"as *;

.l-inner {
  max-width: $max-width;
  margin: auto;
  padding: 0 $content-margin;

  @include mq-down(sm) {
    padding: 0 20px;
  }
}