@use "../../global"as *;

.c-breadcrumb {
  width: 100%;
  height: 60px;
  padding: 0 52px;
  display: flex;
  align-items: center;

  @include mq-down(sm) {
    padding: 0 27px;
    height: 30px;
  }

  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    font-weight: 400px;

    &+& {
      margin-left: 4px;

      &::before {
        content: "";
        display: block;
        width: 15px;
        height: 10px;
        background-image: url(../img/common/icon-arrow.svg);
        background-size: 11px auto;
        background-position: center left;
        background-repeat: no-repeat;
        flex-shrink: 0;
      }
    }
  }
}