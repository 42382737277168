@use "../../global"as *;

.u-fwb {
  font-weight: bold;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-uppercase-none {
  text-transform: none;
}