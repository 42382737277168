@use "../../global"as *;

.c-ruby {
  position: relative;

  &::after {
    content: "";
    display: block;
    font-size: 0.1905em;
    position: absolute;
    right: -1.5em;
    top: 50%;
    transform: translateY(-50%);
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    letter-spacing: 0.2em;
  }

  &--sho {
    &::after {
      content: "しょう";
    }
  }
}