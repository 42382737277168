@use "../../global"as *;

.sp-only {
  display: none;

  @include mq-down(sm) {
    display: block;
  }
}

.pc-only {
  display: block;

  @include mq-down(sm) {
    display: none !important;
  }
}

.sp-only-inline {
  display: none;

  @include mq-down(sm) {
    display: inline;
  }
}

.pc-only-inline {
  display: inline;

  @include mq-down(sm) {
    display: none !important;
  }
}

.sp-only-flex {
  display: none;

  @include mq-down(sm) {
    display: flex;
  }
}

.pc-only-flex {
  display: flex;

  @include mq-down(sm) {
    display: none !important;
  }
}