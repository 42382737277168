@use "../../global"as *;

.c-tac {
  text-align: center;
}

.c-text {
  &--md {
    font-size: clamp(1.4rem, 1.2507vw, 1.6rem);
    line-height: 2;
  }

  &--lg {
    font-size: clamp(1.4rem, 1.407vw, 1.8rem);
    line-height: 2;
  }
}

.color-green {
  color: $color-green;

  a {
    color: $color-green;
  }
}

.wf-kurale {
  font-family: $ff-kurale;
}

.wf-niccone {
  font-family: $ff-niconne;
}