// 変数の定義
// min-width
$breakpoint-up: ("sm": "screen and (min-width: 768px)",
  "md": "screen and (min-width: 1080px)",
  "lg": "screen and (min-width: 1160px)",
  "xl": "screen and (min-width: 1280px)",
) !default;

// max-width
$breakpoint-down: ("sm2": "screen and (max-width: 599px)",
  "sm": "screen and (max-width: 767px)",
  "md": "screen and (max-width: 1179px)",
  "lg": "screen and (max-width: 999px)",
  "header": "screen and (max-width: 1080px)",
  "xl": "screen and (max-width: 1279px)",
) !default;

@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin mq-up-custom($breakpoint: 1000) {
  @media screen and (min-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin mq-down-custom($breakpoint: 1000) {
  @media screen and (max-width: #{$breakpoint}px) {
    @content;
  }
}

@mixin mq-retina() {

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

//placeholder color
@mixin placeholder() {
  &::placeholder {
    @content;
  }

  // IE
  &:-ms-input-placeholder {
    @content;
  }

  // Edge
  &::-ms-input-placeholder {
    @content;
  }
}